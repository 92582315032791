@font-face {
  font-family: Roboto;
  src: url('./fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: Roboto;
  src: url('./fonts/Roboto/Roboto-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: Roboto;
  src: url('./fonts/Roboto/Roboto-Italic.ttf');
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 'bold';
  src: url('./fonts/Poppins/Poppins-ExtraBold.ttf');
}

:root {
  --primary-col: #122642;
  --primary-col-accent: #304f7c;
  --primary-col-light: #9a67ea;
  --primary-col-dark: #673ab7;
  --secondary-col: #1976d2;
  --secondary-col-light: #63a4ff;
  --secondary-col-dark: #1453ac;
  --primary-text-col-light: #fff;
  --secondary-text-col-light-1: #a9a9a9;
  --primary-line-col: #5a687c;
  --black: #020407;
  --project-container-bc: #0e1e35;
  --light-blue-line: #4B87B4;
  --prim-txt-col: #b8bec6;
  --prim-text-col-lighter: #e7e9ec;
  --circle-col: #5a687c;
  --blue: #0044ff;
  --blue-darkened-1: #0035c6;
  --blue-dull: #01018dbe;
  --blue-darkend: #01018dbe;
  --red: #ff0000;
  --yellow-faded: rgb(255, 225, 0, 0.8);
  --text-link: #b8bec6;
  --text-link-hover: #dfe9f5;
}

a {
  display: inline-block;
  color: var(--text-link);
}

a:hover {
  color: var(--text-link);
  color: var(--text-link-hover);
}

* {
  font-family: Roboto;
  color: var(--prim-txt-col);
}

.max-width {
  display: block;
  margin: auto;
  max-width: 1500px;
}

.section-content {
  width: 80%;
  margin: 0 auto;
}

.section-container {
  display: block;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 30vh;
  height: 100%;
  width: 100%;
}

.no-padding {
  padding: 0px;
}

.rsp-row-col-1 {
  --direction: row;
}

.rsp-row-col-1 {
  display: flex;
  row-gap: 1em;
  column-gap: 1em;
  flex-direction: var(--direction);
  align-items: flex-start;
  justify-content: space-between;
}

.rsp-row-col-child-1 {
  --flex-val: 1;
}

.rsp-row-col-child-2 {
  --flex-val: 1;
}

.rsp-row-col-child-1 {
  flex: var(--flex-val);
}

.rsp-row-col-child-2 {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app {
  text-align: center;
  background-color: var(--primary-col);
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.hero-container {
  position: relative;
  background: #020024;
  min-height: 80vh;
  max-height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 0;
  overflow: hidden;
  text-shadow: 5px 5px 10px rgb(2, 4, 7, 0.1);
}

.hero-container :not(:last-child) {
  z-index: 100;
}

.hero-anim-container {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left:50%;
  right: 0;
}

.hero-anim-container span {
  display: block;
  border-radius: 50%;
  background-color: var(--circle-col);
}

.hero-anim-container span:nth-child(1) {
  display: block;
  z-index: 1;
  position: absolute;
  bottom: 50%;
  right: 40%;
  height: 500px;
  width: 500px;
  animation: pulse 10s ease-in-out infinite alternate,
   change-color 10s ease-in-out infinite alternate;
   animation-delay: 2s;
}

.hero-anim-container span:nth-child(2) {
  display: block;
  z-index: 2;
  position: absolute;
  bottom: 30%;
  right: 40%;
  height: 50px;
  width: 50px;
  background-color: var(--red);
  animation: up-down 5s ease-in-out infinite alternate,
  rotate 5s ease-in-out infinite alternate;
}


@keyframes up-down {
  from {
    transform: translateY(-20vh);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes change-color {
  from {
    background-color: var(--circle-col);
  }
  to {
    background-color: var(--yellow-faded);
  }
}

@keyframes rotate {
  0% {
    rotate: -15deg;
  }
  100% {
    rotate: 15deg;
  }
}

@keyframes pulse {
  from {
    scale: 1;
  }

  to {
    scale: 2;
  }
}

.about-container {
  background-color: var(--primary-col);
  background:
   linear-gradient(180deg, rgba(18,38,66,1) 0%, rgba(18,38,66,0.99) 60%, rgba(255,255,255,0) 100%),
   url('./images/backdrops/pattern-1.png');
  background-size: cover;
  padding-bottom: 10rem;
}

.about-gradient {
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  z-index: 99;
  background: rgb(2,0,36);
  background: linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(255,255,255,0) 100%);
}

h1, h2 {
  color: var(--prim-text-col-lighter);
}

h1 {
  font-family: 'Poppins';
  font-size: 84px;
  font-weight: bold;
}

p {
  font-size: 16px;
  text-align: left;
}

p.tool-text {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  font-weight: bold;
  font-size: 1em;
  text-align: center;
  margin-left: 5px;
  font-weight: bold;
}

h2 {
  padding-top: 20px;
  padding-bottom: 10px;
  font-family: 'Poppins';
  font-size: 32px;
  font-weight: bold;
  text-align: left;
}

h3 {
  color: var(--prim-text-col-lighter);
  font-weight: bold;
}

h3.secondary {
  color: darkgray;
  text-align: left;
}

h2.project-type {
  text-align: left;
}

.flex-centered {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.project-type-icon {
  display: flex;
  justify-content: center;
  width: 20%;
  max-width: 90px;
}

.img-100-w {
  display: flex;
  align-items: center;
  user-select: none;
  width: 100%;
}

.project-title-container {
  display: flex;
  flex-direction: row;
  min-height: 20%;
  padding: 10px 0 10px 0;
}

.v-sep {
  display: flex;
  width: 10px;
  background-color: var(--primary-line-col);
  margin-left: 10px;
  margin-right: 10px;
}

.project-titles {
  display: flex;
  flex-direction: column;
}

.project-titles h2 {
  padding: 0;
  font-size: 1.75rem;
}

.root-project-container {
  display: block;
  background-color: var(--primary-col);
  padding-bottom: 100px;
}

.projectContainer {
  display: flex;
  width: 100%;
  row-gap: 10px;
  column-gap: 20px;
  padding: 10px;
  background-color: var(--project-container-bc);
  margin-bottom: 5vh;
  border-width: 2px;
  border-radius: 1em;
  border-style: solid;
  border-color: var(--primary-line-col);
  overflow: scroll;
}

.project-info-cont {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 60%;
  padding: 10px;
  text-align: left;
}


.project-img-container {
  display: flex;
  width: 40%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1vh;
}

.projectSeperator {
  width: 80%;
  height: 1px;
  background-color: var(--secondary-text-col-light-1);
}

.tools-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.tool-container {
  display: flex;
  height: auto;
  width: auto;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-col);
  border-style: solid;
  border-color: var(--primary-line-col);
  border-radius: 10px;
  border-width: 2px;
  margin: 5px;
  padding: 10px;
}

.tool-icon {
  display: block;
  max-height: 100%;
  width: 24px;
  margin: 0;
}

.contact-container {
  background-color: var(--black);
  height: 100%;
  padding: 0px;
  padding-bottom: 100px;
}

.contact-container h2 {
  text-align: center;
}

.contact-channel-container {
  display: flex;
  height: 20%;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.contact-channel-item {
  transition: all .2s ease-in-out;
  display: flex;
  min-height: 24px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.backdrop {
  position: fixed;
  display: flex;
  flex: 1;
  background-color: rgba(2, 0, 36, 0.052);
  align-items: center;
  justify-content: center;
}

.opened-carousel {
  height: 100vh;
  width: 60vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10%;
}

.project-tech-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-top: 5%;
}

.project-tech-container-inner {
  width: 80%;
  display: block;
}

ul {
  padding-left: 0;
  list-style-type: none;
}

li {
  float: left;
  margin: 0;
}

li p {
  padding: 0;
  font-size: calc(10px + 1vh);
}

a.button-1 {
  all: unset;
  width: fit-content;
  color: var(--prim-text-col-lighter);
  background-color: var(--blue);
  text-decoration: none;
  display: inline-block;
  border: none;
  border-radius: 10px;
  padding: 1rem 1.5rem;
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  transition: all .1s ease-in-out;
}

body {
  background-color: var(--primary-col);
}


.button-1:hover,
.button-1:focus {
  background: var(--blue-darkened-1);
}

.button-1:focus-visible {
  border-style: dashed;
  border-color: #fff;
}

nav {
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: #122642;
}

.navbar-container {
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-container > a {
  text-decoration: none;
}

.menu {
  display: flex;
  margin-left: 10%;
  align-items: center;
  justify-content: flex-end;
}

.menu a {
  font-family: 'Poppins';
  margin-right: 20px;
  color: #fff;
  padding: 10px;
  border-radius: 1em;
  text-decoration: none;
}

.menu a:hover {
  color: #fff;
  background-color: #304f7c;
}

.menu-mobile {
  display: none;
  text-align: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 10%;
  transition: all 2.5s ease-in-out;
}

.menu-mobile.is-open {
  display: flex;
  height: auto;
}

.menu-mobile a {
  font-family: 'Poppins';
  display: block;
  color: #fff;
  background-color: #304f7c;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  text-decoration: none;
}

.menu-mobile a:hover {
  color: #122642;
}

.hamburger {
  background: none;
  border: none;
  display: none;
  width: auto;
  height: 24px;
  z-index: 1;
  padding: 0;
  transition: all 0.5s;
}


.hamburger span {
  display: block;
  width: 24px;
  height: 4px;
  margin: 0;
  margin-bottom: 4px;
  background-color: white;
  transition: 0.5s;
}


.hamburger.is-open span:nth-child(1) {
  transform: rotate(45deg);
  transform-origin: top left;
  top:50%;
}

.hamburger.is-open span:nth-child(2) {
  opacity: 0;
}

.hamburger.is-open span:nth-child(3) {
  transform: rotate(-45deg);
  transform-origin: bottom left;
  top:50%;
}

.contact-icon {
  height: 32px;
  width: 32px;
}

/* https://react-bootstrap.github.io/layout/breakpoints/ */
/* x-small */
@media only screen and (max-width: 576px) {
  .section-content {
    width: 90%;
  }

  .projectContainer {
    flex-direction: column;
  }

  .project-info-cont {
    width: 100%;
  }

  .project-img-container {
    width: 100%;
  }

  .rsp-row-col-1 {
    flex-direction: column;
  }

  .rsp-row-col-child-1 {
    --flex-val: 1;
  }

  .rsp-row-col-child-2 {
    --flex-val: 1;
  }

  .menu {
    display: none;
  }

  .hamburger {
    display: block;
  }
}

/* Small */
@media only screen and (min-width: 576px) {
  .section-content {
    width: 90%;
  }

  .projectContainer {
    flex-direction: column;
  }

  .project-info-cont {
    width: 100%;
  }

  .project-img-container {
    width: 100%;
  }

  .rsp-row-col-1 {
    flex-direction: column;
  }

  .rsp-row-col-child-1 {
    --flex-val: 1;
  }

  .rsp-row-col-child-2 {
    --flex-val: 1;
  }

  .menu {
    display: none;
  }

  .hamburger {
    display: block;
  }
}

/* Medium */
@media only screen and (min-width: 768px) {
  .section-content {
    width: 80%;
  }

  .projectContainer {
    flex-direction: row;
  }

  .project-info-cont {
    width: 60%;
  }

  .project-img-container {
    width: 40%;
  }

  .rsp-row-col-1 {
    flex-direction: row;
  }

  .rsp-row-col-child-1 {
    --flex-val: 1;
  }

  .rsp-row-col-child-2 {
    --flex-val: 1;
  }
  .menu {
    display: flex;
  }

  .menu-mobile.is-open {
    display: none;
  }

  .hamburger {
    display: none;
  }
}


/* Large > */
@media only screen and (min-width: 992px) {
  .section-content {
    width: 80%;
  }

  .hero-container {
    height: 90vh;
  }
  .projectContainer {
    flex-direction: row;
  }

  .project-info-cont {
    width: 60%;
  }

  .project-img-container {
    width: 40%;
  }

  .section-container {
    --section-container-width: 90%;
    --section-container-padding-x: 10%;
  }

  .rsp-row-col-1 {
    flex-direction: row;
  }

  .rsp-row-col-child-1 {
    --flex-val: 1;
  }

  .rsp-row-col-child-2 {
    --flex-val: 1;
  }

  .menu {
    display: flex;
  }

  .menu-mobile.is-open {
    display: none;
  }

  .hamburger {
    display: none;
  }
}

/* X-Large > */
@media only screen and (min-width: 1500px) {
  .hero-container {
    min-height: 400px;
    max-height: 400px;
  }
}

@media (hover: hover) {
  .hamburger:hover span {
    background-color: #89a4ca;
  }
  .contact-channel-item:hover {
    transform: scale(1.5);
  }
}
